import 'chart.js';

Chart.defaults.global.tooltips.backgroundColor = '#fff';
Chart.defaults.global.tooltips.titleFontColor = '#000';
Chart.defaults.global.tooltips.bodyFontColor = '#000';
Chart.defaults.global.defaultFontFamily = 'Museo Sans';
Chart.defaults.global.title.fontSize = '20';
Chart.defaults.global.title.fontColor = '#fff';
Chart.defaults.global.title.fontStyle = 'bold';

new Chart(document.getElementById('chart-partnership-1'), {
  type: 'doughnut',
  data: {
    labels: [
      'Betalingsregeling',
      'Zelfhulp',
      'Vrijwilligershulp',
      'Online coach',
      'Gemeentelijke schuldhulp',
    ],
    datasets: [
      {
        label: 'My First Dataset',
        data: [1982, 7389, 653, 1591, 6544],
        backgroundColor: [
          '#6B1E74',
          '#669911',
          '#EED9FB',
          '#F6ABB5',
          '#F6F5AB',
        ],
      },
    ],
  },
  options: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontColor: 'rgb(255, 255, 255)',
        fontFamily: 'Museo Sans',
      },
    },
  },
});
